import clsx from "clsx";
import { footerLinks } from "data/footerLinks";
import { socialMedia } from "data/socialMedia";
import Wrapper from "./Wrapper";

const Footer = () => (
  <footer className="bg-yellow-500 py-10">
    <Wrapper>
      <div className="h-[1px] w-full bg-yellow-600 mt-4 " />
      <div className="flex flex-row gap-x-4 justify-center -mt-4 bg-yellow-500 w-56 m-auto mb-8">
        {socialMedia.map(({ link, icon: Icon, name }) => (
          <a
            target="_blank"
            href={link}
            rel="noreferrer"
            key={name}
            className="w-8 h-8 bg-yellow-600 rounded-full flex justify-center items-center hover:bg-yellow-800 transition-colors"
            aria-label={name}
          >
            <Icon className="w-5 h-5 text-yellow-500" />
          </a>
        ))}
      </div>
      <ul className="flex justify-center text-yellow-800 gap-x-6 gap-y-4 flex-wrap">
        {footerLinks.map((item, key) => (
          <li key={item.title} className="flex items-center uppercase">
            <a
              target="_blank"
              href={item.link}
              rel="noreferrer"
              aria-label={item.title}
            >
              {item.title}
            </a>
            <div
              className={clsx({
                "bg-red-500 w-1.5 h-1.5 rounded-full ml-6":
                  key + 1 < footerLinks.length,
              })}
            />
          </li>
        ))}
      </ul>
      <p className="text-center mt-8 text-yellow-800">
        This website is directed only to U.S. consumers for products and
        services of Mizkan America, Inc. United States. This website is not
        directed to consumers outside of the U.S.
      </p>
      <p className="text-center mt-2 text-yellow-800">
        &copy; 2021 Mizkan America, Inc. United States
      </p>
    </Wrapper>
  </footer>
);

export default Footer;
