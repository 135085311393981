import useSWR from "swr";
import { Response, Card } from "types/response";

const fetcher = (url: string) => fetch(url).then((res) => res.json());

const useCards = () => {
  return useSWR<Response<Card>>("/episodes.json", fetcher, {
    suspense: true,
  });
};

export { useCards };
