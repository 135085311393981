import Logo from "assets/ragu-logo.png";
import Banner from "assets/banner.jpg";
import Wrapper from "./Wrapper";

const Header = () => (
  <header className="bg-yellow-500 pb-[368px] md:pb-[240px]">
    <div className="flex justify-center pb-8 py-12">
      <img
        className="absolute w-auto h-16 md:h-24 top-0 left-0 ml-6 mt-3"
        alt="logo"
        src={Logo}
        height={96}
        width={124}
      />
      <img
        className="object-cover h-52 md:hidden"
        alt="banner"
        src={Banner}
        height={208}
        width={242}
      />
      <img
        className="object-cover h-52 hidden md:block"
        alt="banner"
        src={Banner}
        height={208}
        width={300}
      />
    </div>
    <div className="bg-red-500 w-100 py-6 flex justify-center">
      <Wrapper>
        <h1 className="text-lg font-semibold text-white max-w-2xl text-center">
          RAGÚ’s chaotic and fictional reality competition where four home cooks
          make their signature dinner to prove they don’t just cook, they cook
          like a mother.
        </h1>
      </Wrapper>
    </div>
  </header>
);

export default Header;
