import { useEffect, useState } from "react";

const useContinuousQueryData = <T,>(initialData?: T[]) => {
  const [data, setData] = useState<T[]>(initialData || []);

  useEffect(() => {
    if (initialData) {
      setData(initialData);
    }
  }, [initialData]);

  return data;
};

export { useContinuousQueryData };
