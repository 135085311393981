import Card from "components/Card";
import FindUs from "components/FindUs";
import Footer from "components/Footer";
import Header from "components/Header";
import Wrapper from "components/Wrapper";
import { useCards } from "hooks/useCards";
import { useContinuousQueryData } from "hooks/useContinousQueryData";

const App = () => {
  const { data } = useCards();
  const cardsData = useContinuousQueryData(
    data?.result.sort((a, b) => a.airDate.localeCompare(b.airDate))
  );

  return (
    <>
      <Header />
      <main>
        <Wrapper>
          <div className="grid gap-5 md:gap-12 grid-cols-1 container mx-auto -mt-[342px] md:-mt-48 max-w-md md:max-w-[890px]">
            {cardsData.map(
              ({ content, id, display, formats, episodeDate, recipe }, key) => (
                <Card
                  key={id}
                  index={key + 1}
                  content={{
                    title: content.title,
                    summary: content.summary,
                    img: display.thumbnail.default,
                    src: formats.mp3.src,
                    episodeDate,
                    recipe,
                  }}
                />
              )
            )}
          </div>
        </Wrapper>
        <FindUs />
      </main>
      <Footer />
    </>
  );
};

export default App;
