import { AudioPlayerProvider } from "react-use-audio-player";
import Player from "./Player";
import { Card as CardResponse } from "types/response";
import clsx from "clsx";

interface CardProps {
  content: {
    title: string;
    summary: string;
    img: string;
    src: string;
    episodeDate: string;
    recipe?: CardResponse["recipe"];
  };
  index: number;
}

const Card = ({ content, index }: CardProps) => (
  <div className="b md:p-8 border-1 border-grey-500 flex flex-col max-w-810 bg-white md:flex-row">
    <img
      className="w-full md:w-[300px] md:h-[300px] p-4 md:p-0"
      alt="ragu"
      src={content.img}
      loading="lazy"
      width={300}
      height={300}
    />
    <div className="flex flex-col pt-5 md:pl-12 md:pt-0">
      <h2 className="text-sm font-bold underline decoration-red-600 decoration-2 underline-offset-4 px-5 md:px-0">
        EPISODE #{index}
      </h2>
      <h3 className="mt-6 mb-2 text-red-500 font-bold text-2xl font-bogart px-5 md:px-0">
        {content.title}
      </h3>
      <p
        className={clsx("font-bold leading-7 mb-2 px-5 md:px-0", {
          "mb-6 md:mb-8": !content.recipe?.name,
        })}
      >
        {content.summary}
      </p>
      {content.recipe?.name && (
        <p className="font-bold leading-7 text-red-500 mb-6 md:mb-8 px-5 md:px-0">
          Recipe:{" "}
          <a
            target="_blank"
            href={content.recipe?.url}
            rel="noreferrer"
            className="text-black underline md:no-underline hover:underline"
            aria-label={content.recipe?.name}
          >
            {content.recipe?.name}
          </a>
        </p>
      )}
      <AudioPlayerProvider>
        <Player
          src={content.src}
          episode={index}
          title={content.title}
          episodeDate={content.episodeDate}
        />
      </AudioPlayerProvider>
    </div>
  </div>
);

export default Card;
