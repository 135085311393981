import { findUs } from "data/findUs";
import Wrapper from "./Wrapper";

const FindUs = () => (
  <Wrapper>
    <div className="py-16 flex items-center flex-col gap-4">
      <h2 className="text-lg font-bold">Looking for More Dishes?</h2>
      <a
        id="see-our-recipes"
        target="_blank"
        href="https://www.ragu.com/our-recipes/"
        aria-label="See our recipies"
        rel="noopener noreferrer"
        className="gtm-follow uppercase mb-12 p-3 px-6 cursor-pointer font-bold rounded-md border-1 border-grey-500 hover:bg-red-500 hover:text-white hover:border-red-500 transition-colors"
      >
        see our recipes
      </a>
      <h2 className="text-lg font-bold">You can also find us on</h2>
      <div className="flex gap-4 flex-wrap justify-center flex-col md:flex-row">
        {findUs.map(({ link, icon: Icon, name, id }) => (
          <a
            id={id}
            key={name}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
            aria-label={name}
            className="gtm-follow"
          >
            <Icon className="w-52 h-auto" />
          </a>
        ))}
      </div>
    </div>
  </Wrapper>
);

export default FindUs;
