import { ReactComponent as FacebookSvg } from "assets/facebook.svg";
import { ReactComponent as PinterestSvg } from "assets/pinterest.svg";
import { ReactComponent as InstagramSvg } from "assets/instagram.svg";
import { ReactComponent as YoutubeSvg } from "assets/youtube.svg";

interface SocialMedia {
  name: string;
  link: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const socialMedia: SocialMedia[] = [
  {
    name: "Facebook",
    link: "https://www.facebook.com/ragusauce",
    icon: FacebookSvg,
  },
  {
    name: "Pinterest",
    link: "https://www.pinterest.com/ragusauce/",
    icon: PinterestSvg,
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/ragu/",
    icon: InstagramSvg,
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/user/Ragu",
    icon: YoutubeSvg,
  },
];
