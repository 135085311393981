import { PlayerContextProvider } from "context/PlayerContext";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import TagManager from "react-gtm-module";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

TagManager.initialize({
  gtmId: process.env.REACT_APP_GTM_ID || '',
  auth: process.env.REACT_APP_GTM_AUTH || '',
  preview: process.env.REACT_APP_GTM_PREVIEW || '',
});

ReactDOM.render(
  <React.StrictMode>
    <PlayerContextProvider>
      <Suspense
        fallback={
          <div className="flex justify-center items-center w-screen h-screen">
            <div className="animate-spin rounded-full h-24 w-24 border-b-2 border-yellow-500" />
          </div>
        }
      >
        <App />
      </Suspense>
    </PlayerContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
