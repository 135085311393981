import React from "react";

interface WrapperProps {
  children: React.ReactNode;
}

const Wrapper = ({ children }: WrapperProps) => (
  <div className="px-5 md:px-10">{children}</div>
);

export default Wrapper;
