import { ReactComponent as ApplePodcastSvg } from "assets/apple-podcast.svg";
import { ReactComponent as SpotifySvg } from "assets/spotify.svg";
import { ReactComponent as GooglePodcastSvg } from "assets/google-podcast.svg";

interface FindUs {
  id: string;
  name: string;
  link: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

export const findUs: FindUs[] = [
  {
    id: 'spotify',
    name: "Spotify",
    link: "https://open.spotify.com/show/39VzmyktP7sSi7Rr9ZWqRH",
    icon: SpotifySvg,
  },
  {
    id: 'apple-podcasts',
    name: "Apple podcast",
    link: "https://podcasts.apple.com/us/podcast/ragús-cook-like-a-mother-cook-off/id1604234428",
    icon: ApplePodcastSvg,
  },
  {
    id: 'google-podcasts',
    name: "Google podcast",
    link: "https://podcasts.google.com/feed/aHR0cHM6Ly9hcGkuc3Bva2VubGF5ZXIuY29tL2ZlZWQvY2hhbm5lbC9yYWd1LWNvb2stb2ZmLXBvZGNhc3RzLWV4dC8zYzk5MjliNzI1MzhjMTJiZDkyYWM2NzYyZjhkNzk4YjlkNGU4Y2RjYTc2OTJlYTc0ZjQ2NjA2MWQwMTgxNmNi",
    icon: GooglePodcastSvg,
  }
];
