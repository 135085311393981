interface FooterLink {
  title: string;
  link: string;
}

export const footerLinks: FooterLink[] = [
  {
    title: 'Contact us',
    link: 'https://www.ragu.com/contact-us',
  },
//  {
//    title: 'Where to buy',
//    link: 'https://www.ragu.com/#',
//  },
  {
    title: 'FAQ',
    link: 'https://www.ragu.com/faq',
  },
  {
    title: 'Terms of use',
    link: 'https://www.ragu.com/terms-of-use',
  },
  {
    title: 'Privacy',
    link: 'https://www.ragu.com/privacy-policy',
  },
  {
    title: 'MIZKAN AMERICA, INC.',
    link: 'https://www.ragu.com/about-us',
  },
  {
    title: 'Press Releases',
    link: 'https://www.ragu.com/press-releases',
  }
]
