import { ReactComponent as PauseSvg } from "assets/pause.svg";
import { ReactComponent as PlaySvg } from "assets/play.svg";
import clsx from "clsx";
import { usePlayerContext } from "context/PlayerContext";
import useInterval from "hooks/useInterval";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useAudioPlayer, useAudioPosition } from "react-use-audio-player";
import { secondsToTime } from "utils/secondsToTime";

interface PlayerProps {
  src: string;
  episode: number;
  title: string;
  episodeDate: string;
}

const isComingSoon = (date: string) => new Date(date) >= new Date();

const Player = ({ src, episode, title, episodeDate }: PlayerProps) => {
  const progress = useRef<HTMLDivElement>(null);
  const [initialLoaded, setInitialLoaded] = useState(true);
  const { playingTrack, setPlayingTrack } = usePlayerContext();
  const [comingSoon, setComingSoon] = useState(isComingSoon(episodeDate));

  const { togglePlayPause, playing, pause } = useAudioPlayer({
    src,
    format: ["mp3"],
    html5: true,
    preload: false,
    autoplay: false,
    autoUnlock: true,
  });

  const { duration, seek, percentComplete, position } = useAudioPosition({
    highRefreshRate: true,
  });

  const goToPosition = useCallback(
    (offset: number) => {
      seek(duration * (offset / progress!.current!.clientWidth));
    },
    [duration, seek]
  );

  const isPaused = useMemo(
    () => initialLoaded && !playing,
    [initialLoaded, playing]
  );

  const WrapperComponent = useMemo(
    () => (initialLoaded ? "button" : "div"),
    [initialLoaded]
  );

  const ActionComponent = useMemo(
    () => (initialLoaded ? "div" : "button"),
    [initialLoaded]
  );

  const handleAudioToggle = () => {
    setPlayingTrack(playing ? null : title);
    togglePlayPause();
  };

  useEffect(() => {
    if (playingTrack !== title) {
      pause();
    }
  }, [pause, playingTrack, title]);

  useInterval(() => {
    if (!isComingSoon(episodeDate) && comingSoon !== false) {
      setComingSoon(false);
    }
  }, 60000);

  return comingSoon ? (
    <div className="p-5 text-white text-sm uppercase font-bold flex justify-center items-center border-2 border-red-500 -mx-[1px] -mb-[1px] md:m-0 bg-red-500">
      Coming soon
    </div>
  ) : (
    <WrapperComponent
      aria-label={WrapperComponent === "button" ? `Play ${title}` : undefined}
      className={clsx(
        "p-5 text-white text-sm uppercase font-bold flex justify-between items-center border-2 border-red-500 -mx-[1px] -mb-[1px] md:m-0 transition-all",
        {
          "bg-white": !initialLoaded,
          "bg-red-500": initialLoaded,
        }
      )}
      onClick={() => {
        if (initialLoaded && WrapperComponent === "button") {
          setInitialLoaded(false);

          window.dataLayer.push({
            event: "micrositeEvent",
            micrositeCategory: "raguCookOff",
            micrositeAction: "clickPlay",
            micrositeLabel: `episode-0${episode}`,
          });

          handleAudioToggle();
        }
      }}
    >
      <ActionComponent
        aria-label={
          ActionComponent === "button"
            ? playing
              ? `Pause ${title}`
              : `Play ${title}`
            : undefined
        }
        onClick={() => {
          if (ActionComponent === "button") {
            handleAudioToggle();
          }
        }}
      >
        {playing ? (
          <PauseSvg className="text-red-500" />
        ) : (
          <PlaySvg
            className={clsx("text-red-500", { "text-white": initialLoaded })}
          />
        )}
      </ActionComponent>
      {isPaused ? (
        <span>Play episode</span>
      ) : (
        <div
          ref={progress}
          className="h-[6px] w-full bg-red-400 rounded-full mx-6 cursor-pointer"
          onClick={(e) => {
            goToPosition(e.nativeEvent.offsetX);
          }}
        >
          <div
            className="bg-red-500 h-[6px] rounded-full dark:bg-gray-300"
            style={{ width: `${percentComplete.toFixed(2)}%` }}
          />
        </div>
      )}
      <span className="text-red-500">
        {!isPaused && secondsToTime(position)}
      </span>
    </WrapperComponent>
  );
};

export default Player;
