import { createContext, useContext, useState } from "react";

interface PlayerContextValue {
  playingTrack: string | null;
  setPlayingTrack: React.Dispatch<React.SetStateAction<string | null>>;
}

interface PlayerContextProviderProps {
  children: React.ReactNode;
}

export const PlayerContext = createContext<PlayerContextValue>(null!);
export const usePlayerContext = () => useContext(PlayerContext);

export const PlayerContextProvider = ({
  children,
}: PlayerContextProviderProps) => {
  const [playingTrack, setPlayingTrack] = useState<string | null>(null);

  return (
    <PlayerContext.Provider value={{ playingTrack, setPlayingTrack }}>
      {children}
    </PlayerContext.Provider>
  );
};
